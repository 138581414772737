import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() placeholder = 'SEARCH';
  @Input() inputType = 'text';
  @Input() inputStyle = {};
  @Input() debounceDelay = 1000;
  @Output() search = new EventEmitter<string | number>();

  private emittedValue!: string | number;

  // media search form variables
  @Input() parentForm?: FormGroup;
  globalSearch?: AbstractControl;

  constructor() {}

  ngOnInit() {
    this.setFormControlState();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setFormControlState() {
    if (this.parentForm) {
      // optional field from parent: globalSearch
      this.globalSearch = this.parentForm.controls.globalSearch;

      this.subs.sink = this.parentForm.controls.search.valueChanges
        .pipe(
          debounceTime(this.debounceDelay),
          map((value) => {
            if (!this.globalSearch) return value;

            // note: this prevents globally fetching everything
            // if searchText value is empty and serverSideSearch state is true
            // then set serverSideSearch state to false
            if (value === '') {
              this.globalSearch.setValue(false);
            }

            return value;
          })
        )
        .subscribe((value) => {
          this.emitSearchValue(value);
          value ? '' : this.parentForm?.markAsPristine();
        });
    }
  }

  emitSearchValue(value: string | number) {
    if (this.emittedValue !== value) {
      this.search.emit(value);
      this.emittedValue = value;
    }
  }

  clearFilterInput() {
    if (this.parentForm) {
      this.parentForm.patchValue({ search: '' });
      this.parentForm.markAsPristine();
      this.emitSearchValue('');
    }
  }

  onGlobalSearchButtonClicked(global: boolean) {
    if (!this.globalSearch)
      return console.error('no globalSearch field in parent form');

    this.globalSearch.setValue(global);
  }
}
