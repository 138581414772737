import { TypefaceDefinition } from '@cesdk/cesdk-js';
import { Maybe } from '@designage/gql';
import { IProfile } from '@desquare/interfaces';

interface IFont {
  [id: string]: TypefaceDefinition;
}

export const getFonts = async (currentProfile: Maybe<IProfile>) => {
  // SSP Fonts
  const SspProfiles = [
    '42c0e5b8-86ba-49be-a843-55ac8c4edc86', // desquare staging
    '77e1e3f1-a449-42de-a71a-ef19c25478e7', // ssp prod
    '98b8b285-d8d5-4f0d-ae72-0eb44d9c98ea', // ssp prod
  ];
  const isSSP = currentProfile?.id && SspProfiles.includes(currentProfile?.id);

  const sspTypeFaces: IFont = {
    signpainter: {
      family: 'Sign Painter',
      fonts: [
        {
          weight: 'normal',
          style: 'normal',
          fontURL:
            'https://data.designage.io/fonts/signpainter/signpainter.ttf',
        },
      ],
    },
    verlag: {
      family: 'Verlag',
      fonts: [
        {
          weight: 'normal',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/Verlag/Verlag-Book.otf',
        },
        {
          weight: 'heavy',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/Verlag/Verlag-Black.otf',
        },
        {
          weight: 'heavy',
          style: 'italic',
          fontURL:
            'https://data.designage.io/fonts/Verlag/Verlag-BlackItalic.otf',
        },

        {
          weight: 'light',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/Verlag/Verlag-Light.otf',
        },
        {
          weight: 'light',
          style: 'italic',
          fontURL:
            'https://data.designage.io/fonts/Verlag/Verlag-LightItalic.otf',
        },
        {
          weight: 'extraLight',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/Verlag/Verlag-XLight.otf',
        },
      ],
    },
    verlagCompressed: {
      family: 'Verlag Compressed',
      fonts: [
        {
          weight: 'bold',
          style: 'normal',
          fontURL:
            'https://data.designage.io/fonts/Verlag/VerlagCompressed-Bold.otf',
        },
      ],
    },
    verlagCondensed: {
      family: 'Verlag Condensed',
      fonts: [
        {
          weight: 'light',
          style: 'normal',
          fontURL:
            'https://data.designage.io/fonts/Verlag/VerlagCondensed-Light.otf',
        },
      ],
    },
  };

  // Ferroamp Fonts
  const ferroampProfiles = [
    '42c0e5b8-86ba-49be-a843-55ac8c4edc86', // desquare staging
    '438e6897-3e80-48b5-8f2d-8e7fe181dfb9', // ssp prod
  ];
  const isFerroamp =
    currentProfile?.id && ferroampProfiles.includes(currentProfile?.id);

  const ferroampTypeFaces: IFont = {
    DM_Mono: {
      family: 'DM Mono',
      fonts: [
        {
          weight: 'normal',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/DM_Mono/DMMono-Regular.ttf',
        },
        {
          weight: 'normal',
          style: 'italic',
          fontURL: 'https://data.designage.io/fonts/DM_Mono/DMMono-Italic.ttf',
        },
        {
          weight: 'light',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/DM_Mono/DMMono-Light.ttf',
        },
        {
          weight: 'light',
          style: 'italic',
          fontURL:
            'https://data.designage.io/fonts/DM_Mono/DMMono-LightItalic.ttf',
        },
        {
          weight: 'medium',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/DM_Mono/DMMono-Medium.ttf',
        },
        {
          weight: 'medium',
          style: 'italic',
          fontURL:
            'https://data.designage.io/fonts/DM_Mono/DMMono-MediumItalic.ttf',
        },
      ],
    },
    DM_Sans: {
      family: 'DM Sans',
      fonts: [
        {
          weight: 'normal',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/DM_Sans/DMSans-Regular.ttf',
        },
        {
          weight: 'normal',
          style: 'italic',
          fontURL: 'https://data.designage.io/fonts/DM_Sans/DMSans-Italic.ttf',
        },
        {
          weight: 'bold',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/DM_Sans/DMSans-Bold.ttf',
        },
        {
          weight: 'bold',
          style: 'italic',
          fontURL:
            'https://data.designage.io/fonts/DM_Sans/DMSans-BoldItalic.ttf',
        },
        {
          weight: 'medium',
          style: 'normal',
          fontURL: 'https://data.designage.io/fonts/DM_Sans/DMSans-Medium.ttf',
        },
        {
          weight: 'medium',
          style: 'italic',
          fontURL:
            'https://data.designage.io/fonts/DM_Sans/DMSans-MediumItalic.ttf',
        },
      ],
    },
  };

  let fonts: IFont = {};
  if (isSSP) fonts = sspTypeFaces;
  if (isFerroamp) fonts = ferroampTypeFaces;

  return fonts;
};
