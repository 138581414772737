export function getImageSquaredThumbUrl(url: string) {
  const alteredUrlParams =
    'upload/b_rgb:323b44,bo_1px_solid_rgb:262b30,c_pad,f_auto,h_160,q_auto,w_160/';
  return url.replace('upload/', alteredUrlParams);
}

// v1/PROFILE/3a64bbd1-e51f-47d0-9df4-04e984fa673f/1920x1080png_landscape_vd4xhi

export function getImageNormalThumbUrl(url: string) {
  const alteredUrlParams = 'upload/c_limit,f_auto,h_120,q_auto,w_auto/';
  return url.replace('upload/', alteredUrlParams);
}

export function getVideoNormalThumbUrl(url: string) {
  const alteredUrlParams = 'upload/c_limit,f_auto,h_120,q_auto,w_auto/';
  const lastIndex = url.lastIndexOf('.');
  const webpUrl = url.substring(0, lastIndex) + '.webp';

  return webpUrl.replace('upload/', alteredUrlParams);
}
export function getAssetNormalThumbUrl(url: string) {
  const alteredUrlParams = 'upload/c_limit,f_auto,h_120,q_auto,w_auto/';
  const lastIndex = url.lastIndexOf('.');
  const webpUrl = url.substring(0, lastIndex) + '.webp';

  return webpUrl.replace('upload/', alteredUrlParams);
}
