<div class="content-container-card h-100 d-flex flex-column">
  <div class="d-flex px-1 py-1 border-bottom border-dark">
    <div
      class="d-none d-md-flex align-items-center justify-content-start flex-fill-1"
    >
      <h5 class="my-0 mx-3 text-truncate" [ngbTooltip]="editorHeaderName">
        Creative Editor -
        {{ editorHeaderName ? editorHeaderName : ('NEW_SCENE' | translate) }} -
        {{ initialSceneMode | translate }} mode
      </h5>
    </div>
    <div class="d-flex align-items-center justify-content-end col">
      <!-- toggle role button -->
      <button
        *ngIf="showToggleRoleButton"
        tabindex="-1"
        class="mx-5 btn btn-outline-light"
        (click)="switchRole()"
        placement="left"
      >
        <!-- ngbTooltip="{{ 'CREATIVE_EDITOR_GOTO_TEMPLATE_TT' | translate }}" -->
        {{ 'CREATIVE_EDITOR_EDIT_TEMPLATE' | translate }}
      </button>
      <button
        *ngIf="calledFrom != CeCalledFrom.NEW"
        tabindex="-1"
        class="mx-2 btn btn-outline-success"
        (click)="save()"
        ngbTooltip="{{ 'CREATIVE_EDITOR_SAVE_TT' | translate }}"
        [disabled]="!unsavedChanges"
      >
        {{ 'SAVE' | translate }}
      </button>
      <button
        *ngIf="calledFrom != CeCalledFrom.NEW"
        tabindex="-1"
        class="mx-2 btn btn-outline-success"
        (click)="save((close = true))"
        ngbTooltip="{{ 'CREATIVE_EDITOR_SAVE_TT' | translate }}"
        [disabled]="!unsavedChanges"
      >
        {{ 'SAVE_AND_CLOSE' | translate }}
      </button>
      <button
        *ngIf="currentMediaIsTemplate.value || calledFrom === CeCalledFrom.NEW"
        tabindex="-1"
        class="mx-2 btn btn-outline-primary"
        (click)="saveAsPopup()"
        ngbTooltip="{{ CREATIVE_EDITOR_SAVEAS_TT | translate }}"
      >
        {{ CREATIVE_EDITOR_SAVEAS | translate }}
      </button>
      <button
        tabindex="-1"
        class="mx-2 btn"
        [ngClass]="
          unsavedChanges ? 'btn-outline-danger' : 'btn-outline-success'
        "
        (click)="tryCloseEditor()"
        placement="left"
        ngbTooltip="{{ 'CREATIVE_EDITOR_CLOSE_TT' | translate }}"
      >
        {{ 'CLOSE' | translate }}
      </button>
    </div>
  </div>
  <div #ce_container class="creative-editor"></div>
</div>

<!-- close without saving prompt modal -->
<ng-template #onCloseModal let-closeModal>
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ 'CREATIVE_EDITOR_MODAL_CLOSE_HEADER' | translate }}
      </h4>
    </div>
    <div class="modal-body">
      <p class="p-2">{{ 'CREATIVE_EDITOR_MODAL_CLOSE_PROMPT' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        tabindex="-1"
        type="button"
        class="btn btn-outline-secondary"
        (click)="closeModal.dismiss('Close click')"
        ngbTooltip="{{
          'CREATIVE_EDITOR_MODAL_CLOSE_CANCEL_BUTTON_TT' | translate
        }}"
      >
        {{ 'CANCEL' | translate }}
      </button>
      <button
        tabindex="-1"
        type="button"
        class="btn btn-danger"
        (click)="closeModal.dismiss(); destroyEditor()"
        ngbTooltip="{{
          'CREATIVE_EDITOR_MODAL_CLOSE_CLOSE_BUTTON_TT' | translate
        }}"
      >
        {{ 'CLOSE' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<!-- save progress modal -->
<ng-template #onSaveModal let-saveModal>
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'SAVING' | translate }}</h4>
      <h6 *ngIf="initialSceneMode === 'Video'">
        {{ 'CREATIVE_EDITOR_MODAL_RENDER_FRAME' | translate }}
        {{ saveProgressFrames }} /
        {{ saveProgressTotal }}
      </h6>
    </div>
    <mat-progress-bar
      class="mb-2"
      color="#4175b6"
      [value]="saveProgressPercent"
      mode="'determinate'"
    ></mat-progress-bar>
    <div class="modal-body">
      <p class="p-2">{{ 'CREATIVE_EDITOR_MODAL_SAVE_BODY' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-warning"
        (click)="cancelSave(); saveModal.dismiss('Close click')"
      >
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<!-- Save As prompt Modal -->
<ng-template #onSaveAsModal let-saveAsModal>
  <div class="modal-header">
    <h4 class="modal-title">{{ CREATIVE_EDITOR_SAVEAS_TT | translate }}</h4>
  </div>
  <div class="modal-body p-4">
    <form #imageForm="ngForm">
      <div class="form-group">
        {{ 'CREATIVE_EDITOR_MODAL_IMAGE_NAME' | translate }}
        <div class="btn-group w-100 pt-3" role="group">
          <input
            id="imagename"
            name="imagename"
            type="text"
            [placeholder]="
              'e.g. ' + dateValue.toLocaleDateString() + ' - Filename...'
            "
            [(ngModel)]="editingMediaName"
            #imagename="ngModel"
            class="form-control form-control-dark"
            required
            minlength="4"
          />

          <button
            type="button"
            class="px-3 btn btn-outline-success"
            [disabled]="!imageForm.valid"
            (click)="saveAsModal.dismiss('Save click'); doSaveAs()"
          >
            {{ 'SAVE' | translate }}
          </button>
        </div>
        <div *ngIf="imagename.errors && !imageForm.valid && imageForm.dirty">
          <div *ngIf="imagename.errors.required" class="alert alert-danger">
            {{ 'CREATIVE_EDITOR_MODAL_ALERT_NAME' | translate }}
          </div>
          <div *ngIf="imagename.errors.minlength" class="alert alert-danger">
            {{ 'CREATIVE_EDITOR_MODAL_ALERT_LENGTH' | translate }}
          </div>
        </div>
      </div>
    </form>
    <p class="p-2">{{ 'CREATIVE_EDITOR_MODAL_IMAGE_DESC' | translate }}</p>
  </div>
  <div class="modal-body">
    Save in:
    <app-folder-explorer
      [selectedFolderId]="editingMediaFolderId"
      (selectFolderId)="onSelectFolderId($event)"
    ></app-folder-explorer>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-warning"
      (click)="saveAsModal.dismiss('Cancel click')"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</ng-template>
