<div class="align-items-center justify-content-end btn-group">
  <div class="me-2 text-truncate">{{ 'FILTER_CONTENT_TYPE' | translate }}:</div>

  <div
    class="mx-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates.image ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="filterStates.image"
      [disabled]="!isImageEnabled"
      (click)="onFilterGetMedia('IMAGE')"
      id="filterImage"
    />
    <label class="px-2 form-check-label" for="filterImage">{{
      'IMAGE' | translate
    }}</label>
  </div>

  <div
    class="mx-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates.video ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="filterStates.video"
      [disabled]="!isVideoEnabled"
      (click)="onFilterGetMedia('VIDEO')"
      id="filterVideo"
    />
    <label class="px-2 form-check-label" for="filterVideo">{{
      'VIDEO' | translate
    }}</label>
  </div>

  <div
    class="ms-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates.templateImage ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="filterStates.templateImage"
      [disabled]="!isImageEnabled"
      (click)="onFilterGetMedia('TEMPLATE_IMAGE')"
      id="filterImageTemplate"
    />
    <label class="px-2 form-check-label" for="filterImageTemplate">{{
      'IMAGE_TEMPLATE' | translate
    }}</label>
  </div>

  <div
    class="ms-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates.templateVideo ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="filterStates.templateVideo"
      (click)="onFilterGetMedia('TEMPLATE_VIDEO')"
      [disabled]="!isVideoEnabled"
      id="filterVideoTemplate"
    />
    <label class="px-2 form-check-label" for="filterVideoTemplate">{{
      'VIDEO_TEMPLATE' | translate
    }}</label>
  </div>

  <div
    *ngIf="isSuperAdmin"
    class="ms-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates.playlistImage ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="filterStates.playlistImage"
      (click)="onFilterGetMedia('PLAYLIST_IMAGE')"
      [disabled]="!isImageEnabled"
      id="filterPlaylistImage"
    />
    <label class="px-2 form-check-label" for="filterPlaylistImage">{{
      'PLAYLIST_IMAGE' | translate
    }}</label>
  </div>
  <div
    *ngIf="isSuperAdmin"
    class="ms-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates.playlistVideo ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="filterStates.playlistVideo"
      (click)="onFilterGetMedia('PLAYLIST_VIDEO')"
      [disabled]="!isVideoEnabled"
      id="filterPlaylistVideo"
    />
    <label class="px-2 form-check-label" for="filterPlaylistVideo">{{
      'PLAYLIST_VIDEO' | translate
    }}</label>
  </div>
</div>
