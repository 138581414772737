import {
  DeviceStatusType,
  NotificationContent,
  PlaylistBulkAction,
} from '@designage/gql';

const getDeviceStatusType = (status: string) => {
  switch (status) {
    case DeviceStatusType.Danger:
      return 'text-danger';
    case DeviceStatusType.Warning:
      return 'text-warning';
    case DeviceStatusType.Ok:
      return 'text-primary';
  }

  return 'text-primary';
};

const getNotificationContentStyle = (content: NotificationContent) => {
  switch (content.__typename) {
    case 'MediaUploadNotificationContent':
    case 'MediaUploadLargeNotificationContent':
      return 'text-primary';
    case 'MediaUploadFailedNotificationContent':
      return 'text-warning';
    case 'DeviceStatusNotificationContent':
      const status = content.status;

      if (status) {
        return getDeviceStatusType(status);
      }
  }

  return 'text-primary';
};

const getBulkActionButtonStyle = (action: PlaylistBulkAction) => {
  switch (action) {
    case PlaylistBulkAction.DeletePlaylists:
      return 'btn-outline-danger';
    case PlaylistBulkAction.DuplicateSelected:
      return 'btn-outline-warning';
    case PlaylistBulkAction.PublishSelected:
      return 'btn-outline-success';
    default:
      return 'btn-outline-primary';
  }
};

export const styleUtil = {
  getNotificationContentStyle,
  getBulkActionButtonStyle,
};
