import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaVisibilityType, ResourceType } from '@designage/gql';
import { MediaSourceTypes } from '@desquare/enums';
import {
  IMediaFilterButtonGroupOutput,
  IMediaFilterButtonGroupState,
} from '@desquare/interfaces';
import { CurrentUserService } from '@desquare/services';
import { TranslateModule } from '@ngx-translate/core';

const defaultFilter: IMediaFilterButtonGroupState = {
  image: false,
  video: false,
  templateImage: false,
  templateVideo: false,
  playlistImage: false,
  playlistVideo: false,
};

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
  selector: 'app-media-filter-button-group',
  templateUrl: './media-filter-button-group.component.html',
  styleUrls: ['./media-filter-button-group.component.scss'],
})
export class MediaFilterButtonGroupComponent implements OnInit {
  _limitToMediaTypes = '';
  @Input() set limitToMediaTypes(value: string) {
    this._limitToMediaTypes = value;
    if (value !== '') {
      this.filterStates.image = this.isImageEnabled;
      this.filterStates.templateImage = this.isImageEnabled;
      this.filterStates.video = this.isVideoEnabled;
      this.filterStates.templateVideo = this.isVideoEnabled;
      // emit event
      this.onFilterGetMedia('-');
    }
  }
  get limitToMediaTypes() {
    return this._limitToMediaTypes;
  }

  @Input() filterStates = defaultFilter;

  @Output() filterOutput = new EventEmitter<IMediaFilterButtonGroupOutput[]>();

  get isImageEnabled() {
    return this._limitToMediaTypes !== ''
      ? this._limitToMediaTypes.indexOf(ResourceType.Image) >= 0
      : true;
  }
  get isVideoEnabled() {
    return this._limitToMediaTypes !== ''
      ? this._limitToMediaTypes.indexOf(ResourceType.Video) >= 0
      : true;
  }

  get isSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  constructor(private currentUserService: CurrentUserService) {}

  ngOnInit(): void {}

  onFilterGetMedia(filterValue: string | MediaSourceTypes) {
    const filterOutputList: IMediaFilterButtonGroupOutput[] = [];

    switch (filterValue) {
      case MediaSourceTypes.IMAGE:
        this.filterStates.image = !this.filterStates.image;
        break;
      case MediaSourceTypes.VIDEO:
        this.filterStates.video = !this.filterStates.video;
        break;
      case MediaSourceTypes.TEMPLATE_IMAGE:
        this.filterStates.templateImage = !this.filterStates.templateImage;
        break;
      case MediaSourceTypes.TEMPLATE_VIDEO:
        this.filterStates.templateVideo = !this.filterStates.templateVideo;
        break;
      case MediaSourceTypes.PLAYLIST_IMAGE:
        this.filterStates.playlistImage = !this.filterStates.playlistImage;
        break;
      case MediaSourceTypes.PLAYLIST_VIDEO:
        this.filterStates.playlistVideo = !this.filterStates.playlistVideo;
        break;
      case '-':
        // status has been set somewhere else, just emit it
        break;
      default:
        // invalid input, early exit
        return;
        break;
    }

    // filter logic
    if (this.filterStates.image) {
      filterOutputList.push({
        mediaVisibility: MediaVisibilityType.Default,
        resourceType: ResourceType.Image,
      });
    }
    if (this.filterStates.video) {
      filterOutputList.push({
        mediaVisibility: MediaVisibilityType.Default,
        resourceType: ResourceType.Video,
      });
    }
    if (this.filterStates.templateImage) {
      filterOutputList.push({
        mediaVisibility: MediaVisibilityType.Template,
        resourceType: ResourceType.Image,
      });
    }
    if (this.filterStates.templateVideo) {
      filterOutputList.push({
        mediaVisibility: MediaVisibilityType.Template,
        resourceType: ResourceType.Video,
      });
    }
    if (this.filterStates.playlistImage) {
      filterOutputList.push({
        mediaVisibility: MediaVisibilityType.Playlist,
        resourceType: ResourceType.Image,
      });
    }
    if (this.filterStates.playlistVideo) {
      filterOutputList.push({
        mediaVisibility: MediaVisibilityType.Playlist,
        resourceType: ResourceType.Video,
      });
    }

    this.filterOutput.emit(filterOutputList);
  }
}
