import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { environment } from '@desquare/environments';
import {
  CloudinaryVideo,
  CloudinaryImage,
  Cloudinary,
} from '@cloudinary/url-gen';
import { lazyload, placeholder, CloudinaryModule } from '@cloudinary/ng';
import { limitFit } from '@cloudinary/url-gen/actions/resize';

@Component({
  standalone: true,
  imports: [CloudinaryModule, NgIf],
  selector: 'cl-media',
  template: `
    <advanced-image
      *ngIf="mediaType === 'image'"
      [cldImg]="clImage"
      [plugins]="imagePlugins"
      [width]="width"
      [height]="height"
    ></advanced-image>
    <advanced-video
      *ngIf="mediaType === 'video'"
      [width]="width"
      [height]="height"
      [cldVid]="clVideo"
      controls
      [plugins]="videoPlugins"
      class="cld-video-player"
    ></advanced-video>
    <advanced-video
      *ngIf="mediaType === 'videoPoster'"
      [cldVid]="clVideo"
      [cldPoster]="clVideo"
      [width]="width"
      [height]="height"
    ></advanced-video>
  `,
  // styleUrls: ['./cloudinaryMedia.component.scss'],
  styles: [
    `
      ::ng-deep advanced-image img {
        object-fit: contain !important;
      }
      ::ng-deep .cld-video-player video {
        width: 100% !important;
        height: 100% !important;
        max-height: 60vh !important;
      }
    `,
  ],
})
export class CloudinaryMediaComponent implements OnInit {
  @Input('public-id') publicId!: string;
  @Input('loading') loading = 'lazy';
  @Input('mediaType') mediaType!: 'image' | 'video' | 'raw' | 'videoPoster';
  @Input('width') width: string = '100%';
  @Input('height') height: string = '100%';
  @Input('resizeMethod') resizeMethod!: string | undefined;
  @Input('quality') quality = 'auto';
  @Input('fetch-format') fetchFormat = 'auto';

  clImage!: CloudinaryImage;
  clVideo!: CloudinaryVideo;

  imagePlugins = [lazyload(), placeholder()];
  videoPlugins = [lazyload(), placeholder()];

  constructor() {}
  ngOnInit() {
    const cld = new Cloudinary({
      cloud: {
        cloudName: environment.cloudinary.cloudName,
      },
    });

    if (this.mediaType === 'image') {
      this.clImage = cld.image(this.publicId);
      if (this.resizeMethod === 'limit')
        this.clImage.resize(limitFit().width(this.width).height(this.height));
      this.clImage.format(this.fetchFormat);
    }
    if (this.mediaType === 'video' || this.mediaType === 'videoPoster') {
      this.clVideo = cld.video(this.publicId).format(this.fetchFormat);
      if (this.resizeMethod === 'limit')
        this.clVideo.resize(limitFit().width(this.width).height(this.height));
    }
  }
}
