import { Injectable } from '@angular/core';
import {
  AssetDefinition,
  AssetResult,
  AssetsQueryResult,
  CreativeEngine,
  SceneMode,
  UserInterfaceElements,
} from '@cesdk/cesdk-js';
import {
  Media,
  Maybe,
  ResourceType,
  MediaVisibilityType,
  GetProfileTemplatesGQL,
  Folder,
} from '@designage/gql';
import { CurrentUserService, MediaService } from '@desquare/services';
import { getAssetNormalThumbUrl } from '@desquare/utils';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SourcesService {
  constructor(
    private currentUserService: CurrentUserService,
    private getProfileTemplatesGql: GetProfileTemplatesGQL,
    private mediaService: MediaService
  ) {}

  async loadTemplateSource(
    engine: CreativeEngine,
    sceneMode: SceneMode,
    applyAsset?:
      | ((asset: AssetResult) => Promise<number | undefined>)
      | undefined
  ) {
    // const sourceId = 'ly.img.template';
    // const sourceId =
    //   sceneMode === 'Design'
    //     ? 'designage-image-templates'
    //     : 'designage-video-templates';
    const sourceId =
      sceneMode === 'Design' ? 'ly.img.template' : 'ly.img.video.template';
    engine.asset.addLocalSource(sourceId, undefined, applyAsset);
    // engine.asset.addLocalSource(sourceId, undefined, applyAsset);

    const assets = (await this.getProfileTemplates())?.filter(
      (m) =>
        m.type ===
        (sceneMode === 'Design' ? ResourceType.Image : ResourceType.Video)
    );

    if (assets && assets.length > 0)
      assets.forEach((m) => {
        const template: AssetDefinition = {
          id: m.id,
          label: { en: m.name },
          meta: {
            uri: m.source?.url as string,
            thumbUri: getAssetNormalThumbUrl(m.secureUrl),
            blockType: '//ly.img.ubq/scene',
          },
        };

        // console.log('template', template); // DEBUG

        engine.asset.addAssetToSource(sourceId, template);
      });
  }

  addSources = async (
    engine: CreativeEngine,
    sceneMode: SceneMode,
    folderIds: () => Promise<string[]>,
    profileFolders: Folder[]
  ) => {
    engine.asset.addSource({
      id: 'IMG',
      findAssets: async (queryData) => {
        return await findAssets(ResourceType.Image, queryData?.groups);
      },
      getGroups: folderIds,
      addAsset: () => true,
      removeAsset: () => true,
    });

    if (sceneMode === 'Video') {
      engine.asset.addSource({
        id: 'VID',
        findAssets: async (queryData) => {
          return await findAssets(ResourceType.Video, queryData?.groups);
        },
        getGroups: folderIds,
        addAsset: () => true,
        removeAsset: () => true,
      });
    }

    const getFolderTags = (folderId: Maybe<string>) => {
      const folder = folderId
        ? profileFolders.find((x) => x.id === folderId)
        : undefined;
      return folder ? folder.fullPath?.split('/') : [];
    };

    const findAssets = async (
      type: ResourceType,
      groups?: string[]
    ): Promise<AssetsQueryResult> => {
      // note: currently we don't allow medias in multiple groups (folders)
      // so we can say for sure that groups array will always have 1 element
      const groupId = groups ? groups[0] : undefined;
      const profileId = this.currentUserService.currentProfile?.id;
      let media;
      if (profileId) {
        media = await this.mediaService.getProfileMediaForCreativeEditor(
          profileId,
          groupId
        );
      }
      // console.log('findAssets', media?.length); // DEBUG
      const assets: AssetResult[] = media
        ? media
            .filter(
              (m) =>
                m.type === type && m.visibility === MediaVisibilityType.Default
              // client side filtering
              // && groups?.includes(m.folderId ?? 'root') // if folderId === null then folder goes to root
            )
            .map((m) => {
              return {
                id: m.id,
                groups: m.folderId
                  ? [m.folderId]
                  : [`Designage-${type.toLowerCase()}s`],
                type: `ly.img.${type.toLowerCase()}`,
                label: m.name,
                uri: m.secureUrl,
                // scene: m.source?.url,
                tags: getFolderTags(m.folderId),
                context: {
                  sourceId: `Designage-${type.toLowerCase()}s`,
                },
                meta: {
                  uri: m.secureUrl,
                  thumbUri: getAssetNormalThumbUrl(m.secureUrl),
                  blockType: '//ly.img.ubq/graphic',
                  fillType:
                    type === ResourceType.Image
                      ? '//ly.img.ubq/fill/image'
                      : '//ly.img.ubq/fill/video',
                  width: m.metadata?.width || 0,
                  height: m.metadata?.height || 0,
                },
              } as AssetResult;
            })
        : [];
      // console.log('findAssets:', assets); // DEBUG
      return {
        assets,
        currentPage: 0,
        total: assets.length,
      };
    };
  };

  getProfileTemplates = async () => {
    if (!this.currentUserService.currentProfile) return [];
    const { data } = await lastValueFrom(
      this.getProfileTemplatesGql.fetch({
        profileId: this.currentUserService.currentProfile?.id,
      })
    );
    return data.profile?.template.results as Media[];
  };

  initAssetLibraries = (
    sceneMode: SceneMode,
    profileFolders: Folder[] = [],
    loadTemplates: boolean = true
  ) => {
    const getGroupTitles: (options: {
      group?: string | undefined;
      sourceId?: string | undefined;
    }) => string | undefined = ({ group, sourceId }) => {
      if (!profileFolders) return;
      const groupTitle =
        profileFolders.find(({ id }) => id === group)?.fullPath ?? group;

      // note: If no group is found, the proprty will be undifined and CE will use the ID as title
      const assetLibraryTitle = sourceId === undefined ? undefined : sourceId;

      return !group && !sourceId ? assetLibraryTitle : groupTitle;
    };
    const assetLibraries: UserInterfaceElements.AssetLibraryEntry[] = [];
    // Load image library per defualt.
    const imageLibraries: UserInterfaceElements.AssetLibraryEntry = {
      id: 'Images',
      sourceIds: ['IMG'],
      title: getGroupTitles,
      icon: 'assets/images/image-fill.png',
      gridColumns: 3,
      previewLength: 3,
      gridBackgroundType: 'contain', // : 'contain',
      gridItemHeight: 'auto', // : 'auto',
      canAdd: true,
      canRemove: false,
    };

    // Load if video mode is selected.
    const videoAssets: UserInterfaceElements.AssetLibraryEntry = {
      id: 'Videos',
      sourceIds: ['VID'],
      title: getGroupTitles,
      icon: 'assets/images/video-line.png',
      gridColumns: 3,
      previewLength: 3,
      gridBackgroundType: 'contain', // : 'contain',
      gridItemHeight: 'auto', // : 'auto',
      canAdd: true,
      canRemove: false,
    };

    // Load image library per defualt.
    const stickerLibraries: UserInterfaceElements.AssetLibraryEntry = {
      id: 'Stickers',
      sourceIds: ['ly.img.sticker'],
      gridColumns: 3,
      previewLength: 3,
      canAdd: true,
      canRemove: false,
    };

    // Load if video mode is selected and templates are enabled.
    const videoTemplates: UserInterfaceElements.AssetLibraryEntry = {
      id: 'ly.img.template',
      sourceIds: ['ly.img.video.template'],
      title: 'Video Templates',
      icon: 'assets/images/template-icon.svg',
    };
    const imageTemplates: UserInterfaceElements.AssetLibraryEntry = {
      id: 'ly.img.template',
      sourceIds: ['ly.img.template'],
      title: 'Image Templates',
      icon: 'assets/images/template-icon.svg',
    };

    assetLibraries.push(imageLibraries);
    if (sceneMode === 'Design') assetLibraries.push(imageTemplates);
    if (sceneMode === 'Video') {
      assetLibraries.push(videoAssets);
      if (loadTemplates) assetLibraries.push(videoTemplates);
    }

    const getFolderTags = (folderId: Maybe<string>) => {
      const folder = folderId
        ? profileFolders.find((x) => x.id === folderId)
        : undefined;
      return folder ? folder.fullPath?.split('/') : [];
    };

    return assetLibraries;
  };
}
