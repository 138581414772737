import { FolderGroupType, ResourceType } from '@designage/gql';

const getFolderGroupType = (folderGroupTypeStr: string) => {
  for (const value of Object.values(FolderGroupType)) {
    if (value.toLowerCase() === folderGroupTypeStr.toLowerCase()) {
      return value;
    }
  }

  return FolderGroupType.Profile;
};

const getResourceType = (resourceTypeStr: string) => {
  for (const value of Object.values(ResourceType)) {
    if (value.toLowerCase() === resourceTypeStr.toLowerCase()) {
      return value;
    }
  }

  return ResourceType.Raw;
};

export const stringToEnumUtil = {
  getFolderGroupType,
  getResourceType,
};
