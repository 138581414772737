<app-loader [message]="loaderMessage" *ngIf="loading"></app-loader>
<!-- header -->
<div [hidden]="loading" class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center">
    <h4 class="flex-grow-1">
      {{ headerText | translate }}
    </h4>
    <div class="d-flex">
      <div class="mx-3" ngbDropdown container="body">
        <button
          type="button"
          class="btn btn-outline-primary me-3"
          style="padding: 0.6rem"
          *ngIf="showButtons && showCreativeEditor"
          ngbDropdownToggle
        >
          {{ 'CREATIVE_EDITOR' | translate }}
        </button>
        <div ngbDropdownMenu aria-labelledby="CreativeEditor">
          <button
            ngbDropdownItem
            (click)="createMedia('Design')"
            [ngbTooltip]="'CREATIVE_EDITOR_MODE_IMAGE_TT' | translate"
            placement="left"
          >
            {{ 'CREATIVE_EDITOR_MODE_IMAGE' | translate }}
          </button>
          <button
            ngbDropdownItem
            (click)="createMedia('Video')"
            [ngbTooltip]="'CREATIVE_EDITOR_MODE_VIDEO_TT' | translate"
            placement="left"
          >
            {{ 'CREATIVE_EDITOR_MODE_VIDEO' | translate }}
          </button>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="openUploadWidget()"
        *ngIf="showButtons"
      >
        {{ 'UPLOAD_MEDIA' | translate }}
      </button>
    </div>
  </div>

  <div class="my-2 d-flex justify-content-between align-items-center">
    <form [formGroup]="mediaSearchForm" class="flex-grow-1 d-flex">
      <app-search-input
        class="w-100"
        [debounceDelay]="500"
        [parentForm]="mediaSearchForm"
        (search)="search($event)"
      ></app-search-input>
    </form>
  </div>

  <!-- body -->
  <!-- media-list header -->
  <div
    class="pe-4 d-flex justify-content-between align-items-center overflow-hidden filter-bg"
  >
    <div class="folders-toggle" (click)="folderExplorer = !folderExplorer">
      <h6 class="py-2 ps-3">
        <i class="ri-folder-fill me-2"></i>
        {{ folderExplorer ? 'Hide folders' : 'Show folders' }}
      </h6>
    </div>
    <!-- filter buttons -->
    <app-media-filter-button-group
      [limitToMediaTypes]="mediaTypes"
      (filterOutput)="onFilterOutput($event)"
    >
    </app-media-filter-button-group>
  </div>
  <as-split unit="pixel" gutterSize="0" useTransition="true">
    <as-split-area minSize="10" size="250" [visible]="folderExplorer" order="1">
      <!-- folder explorer -->
      <app-folder-explorer
        [selectedFolderId]="getSelectedFolderId()"
        (selectFolderId)="onSelectFolderId($event)"
        (folderIds)="folderTreeIds = $event"
      ></app-folder-explorer>
    </as-split-area>
    <as-split-area size="*" class="table-area" order="2">
      <!-- media-list -->
      <!-- configId="media-list" -->
      <designage-data-table
        [columnSelector]="false"
        [showFilter]="false"
        [data]="mediaList$ | async"
        [columns]="desColumns"
        [connectedTableList]="folderTreeIds()"
        [dragDisabled]="false"
        [loading]="datatableLoading"
        [(selectedRows)]="mediaList"
        (selectedRowsChange)="onSelectedChange()"
        [showBulkActions]="showBulkAction"
        [alwaysSort]="true"
        (rowClick)="onRowClick($event)"
        [customComponent]="[thumbnail, fileSize, createdAt]"
        [customDragClass]="'mediaDragDrop'"
        [showMultiSelect]="
          enableCheckbox && !(enableSlidePanel && isSlidePanelOpen())
        "
        [rowActiveInSlidingPanel]="selectedMediaId()"
      >
        <!-- Bulk Actions Template -->
        <ng-container ngProjectAs="bulkActions">
          <button
            *ngFor="let action of mediaListActions"
            class="my-1 btn btn-sm mx-2"
            [ngClass]="bulkActionsUtil.getButtonClass(action).class"
            [ngbTooltip]="
              bulkActionsUtil.getButtonClass(action).toolTip | translate
            "
            (click)="bulkActionClick(action)"
          >
            {{ action | translate }}
          </button>
        </ng-container>
      </designage-data-table>

      <ng-template #thumbnail let-row>
        <ng-container [ngSwitch]="row.type">
          <ng-container *ngSwitchCase="'IMAGE'">
            <cl-media
              ngbTooltip="{{ row.name }}"
              class="cl-media-image"
              [public-id]="row.publicId"
              mediaType="image"
              loading="lazy"
              width="120"
              height="120"
              resizeMethod="limit"
              quality="auto"
              fetch-format="webp"
            ></cl-media>
          </ng-container>

          <ng-container *ngSwitchCase="'VIDEO'">
            <cl-media
              ngbTooltip="{{ row.name }}"
              class="cl-media-video"
              [public-id]="row.publicId"
              mediaType="videoPoster"
              loading="lazy"
              width="120"
              height="120"
              resizeMethod="limit"
              quality="auto"
              fetch-format="webp"
            ></cl-media>
          </ng-container>
          <div *ngSwitchDefault>
            {{ row | json }}
            <img [src]="placeHolderImage" class="asset-img" />
          </div>
        </ng-container>
      </ng-template>

      <ng-template #fileSize let-row>
        {{ row.metadata?.bytes | fileSize }}
      </ng-template>

      <ng-template #dragTemplate let-row>
        <img *cdkDragPreview [src]="row.poster" [alt]="row.name" />
      </ng-template>

      <ng-template #createdAt let-row>
        <table-dateTime
          [data]="row.createdAt"
          [tooltipPipeArgs]="'medium'"
        ></table-dateTime>
      </ng-template>

      <div [class.hidden]="datatableLoading || loading || !hasNoData">
        <hr />
        <h4 class="unavailable-message">
          {{ 'NO_CONTENT_FOUND' | translate }}
        </h4>
      </div>
      <!-- <div class="d-flex flex-column overflow-hidden">
      </div> -->
    </as-split-area>
  </as-split>
</div>

<!-- <div class="overflow-hidden h-100 ">

</div> -->
<!-- <button id="upload_widget">Trigger</button> -->

<!-- Media Manage Panel -->
<router-outlet *ngIf="enableSlidePanel"></router-outlet>
