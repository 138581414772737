import { HtmlImageLayer, cancelCurrentlyRunningPlugins, HtmlVideoLayer } from '@cloudinary/html';
export { accessibility, lazyload, placeholder, responsive } from '@cloudinary/html';
import * as i0 from '@angular/core';
import { VERSION, Component, Input, EventEmitter, Output, NgModule } from '@angular/core';
import '@cloudinary/url-gen/assets/CloudinaryImage';
import '@cloudinary/url-gen';
let APP_VERSION = '2.0.2';

/**
 * Used by SDK Analytics as a token (?a_{token})
 */
const SDKAnalyticsConstants = {
  sdkSemver: APP_VERSION,
  techVersion: VERSION.full,
  sdkCode: 'K'
};

/**
 * @mixin AngularSDK
 * @description The Cloudinary Angular SDK contains components like \<advanced-image\> to easily render your media assets from
 * Cloudinary. The SDK also comes with support for optional JS plugins that make the components smart, with features
 * like lazy loading, placeholder, accessibility & responsiveness.
 *
 * @example
 * <caption>
 *  Please note that the order of the plugins is important. See {@link https://cloudinary.com/documentation/sdks/js/frontend-frameworks/index.html#plugin-order|Plugin Order} for more details.
 * </caption>
 * // In your app.module.ts inject the library.
 * import { CloudinaryModule} from '@cloudinary/angular';
 *
 * imports: [
 *   ...,
 *   CloudinaryModule,
 * ],
 *
 * // In your component.ts use `@cloudinary/url-gen` to generate your transformations.
 * // Import the plugins you wish to use
 *
 * import {CloudinaryImage} from "@cloudinary/url-gen/assets/CloudinaryImage";
 * import {
 *  AdvancedImage,
 *  accessibility,
 *  responsive,
 *  lazyload,
 *  placeholder
 * } from '@cloudinary/angular';
 *
 * ngOnInit() {
 *   const myCld = new Cloudinary({ cloudName: 'demo'});
 *   this.img = myCld().image('sample');
 *
 *   this.plugins = [lazyload(), placeholder()]
 * }
 *
 * // In your view add the component with your transformation.
 * <advanced-image [cldImg]="this.img" [plugins]="this.plugins"></advanced-image>
 */
/**
 * @memberOf AngularSDK
 * @type {Component}
 * @description The Cloudinary image component.
 * @prop {CloudinaryImage} transformation Generated by @cloudinary/url-gen
 * @prop {Plugins} plugins Advanced image component plugins accessibility(), responsive(), lazyload(), placeholder()
 * @prop imageAttributes Optional attributes include alt, width, height, loading
 */
class CloudinaryImageComponent {
  constructor(el) {
    this.el = el;
  }
  /**
   * On init creates a new HTMLLayer instance and initializes with ref to img element,
   * user generated cloudinaryImage and the plugins to be used.
   */
  ngOnInit() {
    this.htmlLayerInstance = new HtmlImageLayer(this.el.nativeElement.children[0], this.cldImg, this.plugins, SDKAnalyticsConstants);
    this.addAttributes();
  }
  /**
   * On update, we cancel running plugins and update the image instance with the state of user
   * cloudinaryImage and the state of plugins.
   */
  ngOnChanges() {
    if (this.htmlLayerInstance) {
      cancelCurrentlyRunningPlugins(this.htmlLayerInstance.htmlPluginState);
      this.htmlLayerInstance.update(this.cldImg, this.plugins, SDKAnalyticsConstants);
    }
  }
  /**
   * On destroy, we cancel the currently running plugins.
   */
  ngOnDestroy() {
    // safely cancel running events on destroy
    cancelCurrentlyRunningPlugins(this.htmlLayerInstance.htmlPluginState);
  }
  /**
   * Add attributes to img element.
   */
  addAttributes() {
    ['alt', 'width', 'height', 'loading'].forEach(attr => {
      if (this[attr]) {
        this.el.nativeElement.children[0].setAttribute(attr, this[attr]);
        this.el.nativeElement.removeAttribute(attr);
      }
    });
  }
}
CloudinaryImageComponent.ɵfac = function CloudinaryImageComponent_Factory(t) {
  return new (t || CloudinaryImageComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
CloudinaryImageComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CloudinaryImageComponent,
  selectors: [["advanced-image"]],
  inputs: {
    cldImg: "cldImg",
    plugins: "plugins",
    alt: "alt",
    width: "width",
    height: "height",
    loading: "loading"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 1,
  vars: 0,
  template: function CloudinaryImageComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "img");
    }
  },
  styles: ["[_nghost-%COMP%]{display:block}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CloudinaryImageComponent, [{
    type: Component,
    args: [{
      selector: 'advanced-image',
      template: `
    <img />
  `,
      styleUrls: ['./cloudinary-image.component.css']
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    cldImg: [{
      type: Input,
      args: ['cldImg']
    }],
    plugins: [{
      type: Input,
      args: ['plugins']
    }],
    alt: [{
      type: Input,
      args: ['alt']
    }],
    width: [{
      type: Input,
      args: ['width']
    }],
    height: [{
      type: Input,
      args: ['height']
    }],
    loading: [{
      type: Input,
      args: ['loading']
    }]
  });
})();

/**
 * @memberOf AngularSDK
 * @type {Component}
 * @description The Cloudinary video component.
 * @prop {CloudinaryVideo} transformation Generated by @cloudinary/url-gen
 * @prop {VideoPoster} transformation Generated by @cloudinary/url-gen
 * @prop {Plugins} plugins Advanced image component plugins lazyload()
 * @prop videoAttributes Optional attributes include controls, loop, muted, poster, preload, autoplay
 * @prop videoEvents Optional video events include play, loadstart, playing, error, ended
 * @prop {VideoSources} sources Optional sources to generate
 * @example
 *  <caption>
 *  Using custom defined resources.
 * </caption>
 *   vid = new CloudinaryVideo('dog', {cloudName: 'demo'});
 *   sources = [
 {
        type: 'mp4',
        codecs: ['vp8', 'vorbis'],
        transcode: videoCodec(auto())
},
 {
        type: 'webm',
        codecs: ['avc1.4D401E', 'mp4a.40.2'],
         transcode: videoCodec(auto())
      }];
 *
 * <advanced-video [cldvid]="vid" [sources]="sources" controls></advanced-video>
 */
class CloudinaryVideoComponent {
  constructor(el) {
    this.el = el;
    // Event emitters
    this.play = new EventEmitter();
    this.loadstart = new EventEmitter();
    this.playing = new EventEmitter();
    this.error = new EventEmitter();
    this.ended = new EventEmitter();
    // supported video attributes
    this.controls = this.el.nativeElement.attributes.controls;
    this.loop = this.el.nativeElement.attributes.loop;
    this.muted = this.el.nativeElement.attributes.muted;
    this.preload = this.el.nativeElement.attributes.preload;
    this.autoPlay = this.el.nativeElement.attributes.autoplay;
    this.playsInline = this.el.nativeElement.attributes.playsInline;
  }
  /**
   * On init creates a new HTMLVideoLayer instance and initializes with ref to video element,
   * user generated cloudinaryVideo and the plugins to be used.
   */
  ngOnInit() {
    this.htmlVideoLayerInstance = new HtmlVideoLayer(this.el.nativeElement.children[0], this.cldVid, this.sources, this.plugins, this.getVideoAttributes(), this.cldPoster);
    // check if video should be muted. We need to take care of this here since Angular has a bug with binding the muted
    // attribute
    if (this.muted) {
      this.el.nativeElement.children[0].muted = true;
    }
    // attach ref to innerRef input
    this.attachRef();
  }
  /**
   * On update, we cancel running plugins and update the video instance if the src
   * was changed.
   */
  ngOnChanges() {
    if (this.htmlVideoLayerInstance) {
      cancelCurrentlyRunningPlugins(this.htmlVideoLayerInstance.htmlPluginState);
      this.htmlVideoLayerInstance.update(this.cldVid, this.sources, this.plugins, this.getVideoAttributes(), this.cldPoster);
    }
  }
  /**
   * On destroy, we cancel the currently running plugins.
   */
  ngOnDestroy() {
    // Safely cancel running events on destroy
    cancelCurrentlyRunningPlugins(this.htmlVideoLayerInstance.htmlPluginState);
  }
  /**
   * Returns video attributes.
   */
  getVideoAttributes() {
    return {
      controls: this.controls,
      loop: this.loop,
      muted: this.muted,
      poster: this.poster,
      preload: this.preload,
      autoplay: this.autoPlay,
      playsinline: this.playsInline
    };
  }
  emitPlayEvent() {
    this.play.emit();
  }
  emitLoadstartEvent() {
    this.loadstart.emit();
  }
  emitPlayingEvent() {
    this.playing.emit();
  }
  emitErrorEvent() {
    this.error.emit();
  }
  emitEndedEvent() {
    this.ended.emit();
  }
  /**
   * Attach both this.videoRef and props.innerRef as ref to the given element.
   */
  attachRef() {
    if (this.innerRef) {
      this.innerRef.nativeElement = this.el.nativeElement.children[0];
    }
  }
}
CloudinaryVideoComponent.ɵfac = function CloudinaryVideoComponent_Factory(t) {
  return new (t || CloudinaryVideoComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
CloudinaryVideoComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CloudinaryVideoComponent,
  selectors: [["advanced-video"]],
  inputs: {
    cldVid: "cldVid",
    cldPoster: "cldPoster",
    sources: "sources",
    plugins: "plugins",
    poster: "poster",
    innerRef: "innerRef"
  },
  outputs: {
    play: "play",
    loadstart: "loadstart",
    playing: "playing",
    error: "error",
    ended: "ended"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 1,
  vars: 0,
  consts: [[3, "play", "loadstart", "playing", "error", "ended"]],
  template: function CloudinaryVideoComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "video", 0);
      i0.ɵɵlistener("play", function CloudinaryVideoComponent_Template_video_play_0_listener() {
        return ctx.emitPlayEvent();
      })("loadstart", function CloudinaryVideoComponent_Template_video_loadstart_0_listener() {
        return ctx.emitLoadstartEvent();
      })("playing", function CloudinaryVideoComponent_Template_video_playing_0_listener() {
        return ctx.emitPlayingEvent();
      })("error", function CloudinaryVideoComponent_Template_video_error_0_listener() {
        return ctx.emitErrorEvent;
      })("ended", function CloudinaryVideoComponent_Template_video_ended_0_listener() {
        return ctx.emitEndedEvent;
      });
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CloudinaryVideoComponent, [{
    type: Component,
    args: [{
      // tslint:disable-next-line:component-selector
      selector: 'advanced-video',
      template: `<video (play)="emitPlayEvent()"
                    (loadstart)="emitLoadstartEvent()"
                    (playing)="emitPlayingEvent()"
                    (error)="emitErrorEvent"
                    (ended)="emitEndedEvent">
            </video>`
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    cldVid: [{
      type: Input,
      args: ['cldVid']
    }],
    cldPoster: [{
      type: Input,
      args: ['cldPoster']
    }],
    sources: [{
      type: Input,
      args: ['sources']
    }],
    plugins: [{
      type: Input,
      args: ['plugins']
    }],
    poster: [{
      type: Input,
      args: ['poster']
    }],
    innerRef: [{
      type: Input,
      args: ['innerRef']
    }],
    play: [{
      type: Output
    }],
    loadstart: [{
      type: Output
    }],
    playing: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    ended: [{
      type: Output
    }]
  });
})();
class CloudinaryModule {}
CloudinaryModule.ɵfac = function CloudinaryModule_Factory(t) {
  return new (t || CloudinaryModule)();
};
CloudinaryModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CloudinaryModule
});
CloudinaryModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CloudinaryModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [CloudinaryImageComponent, CloudinaryVideoComponent],
      exports: [CloudinaryImageComponent, CloudinaryVideoComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of cloudinary-library
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CloudinaryImageComponent, CloudinaryModule, CloudinaryVideoComponent };
